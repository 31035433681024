//APIManager.js
import axios from 'axios';
import { API_ENDPOINTS, APPLICATION_ID } from './APIConfig';
import { v4 as uuidv4 } from 'uuid'; 

const BACKBLAZE_BUCKET_NAME = "portalbucket";

// AUTH
const handleTokenRefresh = async () => {
  try {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) throw new Error('No refresh token available');

    const response = await axios.post(API_ENDPOINTS.REFRESH_TOKEN, { refresh_token: refreshToken });
    const { access_token } = response.data;

    localStorage.setItem('access_token', access_token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

    return access_token;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error;
  }
};

const executeWithTokenRefresh = async (apiCall) => {
  try {
    return await apiCall();
  } catch (error) {
    if (error.response && error.response.status === 401) {
      try {
        await handleTokenRefresh();
        return await apiCall();
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        throw refreshError;
      }
    }
    throw error;
  }
};

// USER
export const fetchUserById = async (userId) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.GET_USER(userId))
    );
    return response.data;
  } catch (error) {
    console.error('Error getting user:', error);
    return {};
  }
};

export const followUser = async (userId) => {
  try {
    const response = await executeWithTokenRefresh(() =>  {
      axios.post(`${API_ENDPOINTS.USERS}/${userId}/follow`);
    });
    return response.data;
  } catch (error) {
    console.error('Error unfollowing user:', error);
    throw error;
  }
};

export const unfollowUser = async (userId) => {
  try {
    const response = await executeWithTokenRefresh(() =>  {
      axios.delete(`${API_ENDPOINTS.USERS}/${userId}/follow`);
    });
    return response.data;
  } catch (error) {
    console.error('Error unfollowing user:', error);
    throw error;
  }
};

// SOUNDS
export const createSound = async (soundData) => {
  const uniqueFolder = uuidv4();
  const uniqueFileName = `${uniqueFolder}/${soundData.file.name}`; // Create unique filename for the sound file
  const fileNameWithoutExtension = soundData.file.name.replace(/\.[^/.]+$/, ""); // Remove extension for the preview
  const audioPreviewFileName = `${uniqueFolder}/${fileNameWithoutExtension}_preview.mp3`; // Preview file name

  let imageFileName = null;
  if (soundData.image) {
    imageFileName = `${uniqueFolder}/${soundData.image.name}`; // Create a unique filename for the image if provided
  }

  try {
    // Upload the file and optional image to your server
    const uploadResult = await uploadFileToServer(soundData.file, uniqueFileName, audioPreviewFileName, soundData.image, imageFileName);

    // Create the sound entry in your API
    const jsonData = {
      application_id: APPLICATION_ID,
      name: soundData.name,
      image: uploadResult.image_file_url || "", // Use the image file URL if provided
      file_name: uniqueFileName,
      bucket_name: BACKBLAZE_BUCKET_NAME,
      file: "", 
      tags: soundData.tags,
      genres: soundData.genres,
      instruments: soundData.instruments,
      audio_preview: uploadResult.preview_file_url,
      type: {
        id: soundData.typeId,
      },
      key: soundData.key,
      file_backup: "",
      description: soundData.description,
      bpm: soundData.bpm,
      cost_in_credits: soundData.costInCredits,
    };

    if (soundData.status) {
      jsonData.status = soundData.status;
    }
    
    const soundwareToken = localStorage.getItem('access_token');

    const response = await axios.post(API_ENDPOINTS.SOUNDS, jsonData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${soundwareToken}`
      },
    });

    return { ...response.data, fileSize: soundData.file.size };
  } catch (error) {
    console.error('Error creating sound:', error);
    throw error;
  }
};

// Fetch Sounds
export const fetchSounds = async (page, pageSize, filters = {}) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.SOUNDS, {
        params: {
          page_number: page,
          page_size: pageSize,
          application: APPLICATION_ID,
          status: "active",
          ...filters,
        }
      })
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching sounds:', error);
    return { items: [], total: 0 };
  }
};

export const fetchFeaturedSounds = async (page, pageSize) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.SOUNDS, {
        params: {
          page_number: 1,
          page_size: 10,
          application: APPLICATION_ID,
          status: "active",
          featured: true
        }
      })
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching featured sounds:', error);
    return { items: [], total: 0 };
  }
};

export const fetchSoundById = async (soundId) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.GET_SOUND(soundId))
    );
    return response.data;
  } catch (error) {
    console.error('Error getting sound:', error);
    return {};
  }
};

// Meta Data
export const fetchSoundTypes = async () => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.SOUND_TYPES)
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching sound types:', error);
    return [];
  }
};

export const fetchTags = async () => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.TAGS)
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching tags:', error);
    return [];
  }
};


export const fetchGenres = async () => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.GENRES)
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching genres:', error);
    return [];
  }
};

export const fetchInstruments = async () => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.INSTRUMENTS)
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching instruments:', error);
    return [];
  }
};

//Liking
export const likeSound = async (soundId) => {
  try {
    await executeWithTokenRefresh(() => 
      axios.post(API_ENDPOINTS.LIKE_SOUND(soundId))
    );
    return true;
  } catch (error) {
    console.error('Error liking sound:', error);
    return false;
  }
};

export const unlikeSound = async (soundId) => {
  try {
    await executeWithTokenRefresh(() => 
      axios.delete(API_ENDPOINTS.UNLIKE_SOUND(soundId))
    );
    return true;
  } catch (error) {
    console.error('Error unliking sound:', error);
    return false;
  }
};
// Purchases
export const purchaseSound = async (soundId) => {
  try {
    await executeWithTokenRefresh(() => 
      axios.post(API_ENDPOINTS.PURCHASE_SOUND(soundId))
    );
    return true;
  } catch (error) {
    console.error('Error purchasing sound:', error);
    return false;
  }
};

// UPLOAD SOUND
const uploadFileToServer = async (file, uniqueFileName, audioPreviewFileName, imageFile = null, imageFileName = null) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('file_name', uniqueFileName); // Append the unique filename to the form data
  formData.append('audio_preview_file_name', audioPreviewFileName); // Append the preview filename

  // If image file and image file name are provided, append them to the form data
  if (imageFile && imageFileName) {
    formData.append('image_file', imageFile); // Append image file
    formData.append('image_file_name', imageFileName); // Append image file name
  }
  
  try {
    const response = await axios.post(`${API_ENDPOINTS.UPLOAD_FILE}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,  // Unlimited timeout (0 means no timeout)
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading file to server:', error);
    throw error;
  }
};

//Downloads
export const getDownloadURL = async (soundId) => {
  try {
    const response = await executeWithTokenRefresh(() => 
      axios.get(API_ENDPOINTS.GET_SOUND_DOWNLOAD_URL(soundId))
    );
    return response.data.downloadUrl;
  } catch (error) {
    console.error('Error getting download URL:', error);
    throw error;
  }
};


//Audio Apis
export const analyzeAudio = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post('http://137.184.116.225:8000/analyze_audio', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error analyzing audio:', error);
    throw error;
  }
};