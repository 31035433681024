import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ViewCacheProvider } from './context/ViewCacheContext';
import Layout from './components/Layout';
import Login from './pages/Login';
import Home from './pages/Home';
import MySounds from './pages/MySounds';
import Upload from './pages/Upload';
import Likes from './pages/Likes';
import PrivateRoute from './components/PrivateRoute';
import UserProfile from './pages/UserProfile';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <ViewCacheProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<PrivateRoute><Layout /></PrivateRoute>}>
              <Route index element={<Home />} />
              <Route path="/my-sounds" element={<MySounds />} />
              <Route path="/likes" element={<Likes />} />
              <Route path="upload" element={<Upload />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/profile/:userId" element={<UserProfile />} />
              {/* Add more routes here as needed */}
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </ViewCacheProvider>
    </AuthProvider>
  );
}

export default App;