import React, { useContext } from 'react';
import SoundBrowser from '../components/SoundBrowser';
import { AuthContext } from '../context/AuthContext';

const MySoundsIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
  </svg>
);


const MySounds = () => {
  const { user } = useContext(AuthContext);
  
  return <SoundBrowser 
    additionalFilters={{ owner: user.id }} 
    cacheKey="mySounds" 
    title="Sounds"
    icon={MySoundsIcon}
  />;
};

export default MySounds;


