import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { fetchFeaturedSounds } from '../api/APIManager';
import { DEFAULT_SOUND_IMAGE } from '../api/APIConfig';
import Spinner from '../components/Spinner';

const FeaturedSoundsCarousel = ({ onPlay }) => {
  const [featuredSounds, setFeaturedSounds] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const nextSound = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % featuredSounds.length);
    setImageLoaded(false);
  }, [featuredSounds.length]);

  const prevSound = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + featuredSounds.length) % featuredSounds.length);
    setImageLoaded(false);
  }, [featuredSounds.length]);

  useEffect(() => {
    const loadFeaturedSounds = async () => {
      try {
        setIsLoading(true);
        const response = await fetchFeaturedSounds();
        if (response && response.items) {
          setFeaturedSounds(response.items);
        } else {
          setError('No featured sounds available');
        }
      } catch (error) {
        console.error('Error fetching featured sounds:', error);
        setError('Failed to load featured sounds');
      } finally {
        setIsLoading(false);
      }
    };
    loadFeaturedSounds();
  }, []);

  useEffect(() => {
    if (featuredSounds.length > 1) {
      const interval = setInterval(nextSound, 5000); // Auto-animate every 5 seconds
      return () => clearInterval(interval);
    }
  }, [featuredSounds.length, nextSound]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  if (isLoading) {
    return (
      <div className="w-full h-64 mb-8 flex items-center justify-center bg-bg-secondary rounded-lg shadow-[0_10px_30px_rgba(0,0,0,0.05),_0_5px_15px_rgba(0,0,0,0.05)]">
        <Spinner />
      </div>
    );
  }

  if (error || featuredSounds.length === 0) {
    return (
      <div className="w-full h-64 mb-8 flex items-center justify-center bg-bg-secondary rounded-lg shadow-[0_10px_30px_rgba(0,0,0,0.1),_0_5px_15px_rgba(0,0,0,0.2)] text-text-primary">
        {error || 'No featured sounds available'}
      </div>
    );
  }

  const currentSound = featuredSounds[currentIndex];

  return (
    <div className="relative w-full h-64 mb-4 overflow-hidden rounded-lg bg-gradient-to-r from-accent-start to-accent-end shadow-[0_10px_30px_rgba(0,0,0,0.1),_0_5px_15px_rgba(0,0,0,0.2)]">
      <AnimatePresence initial={false}>
        <motion.div
          key={currentSound.id}
          className="absolute inset-0 flex items-center justify-center"
          initial={{ opacity: 0, x: '100%' }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: '-100%' }}
          transition={{ duration: 0.5 }}
        >
          <img
            src={currentSound.image || currentSound.image2 || DEFAULT_SOUND_IMAGE}
            alt={currentSound.name}
            className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
            onLoad={handleImageLoad}
          />
          <div className="absolute inset-0 bg-black bg-opacity-50" />
          <div className="relative z-10 text-white p-6 max-w-2xl w-full text-center">
            <h2 className="text-3xl font-bold mb-2">{currentSound.name}</h2>
            <p className="text-lg mb-4">{currentSound.description}</p>
            <button
              onClick={() => onPlay(currentSound)}
              className="bg-white text-black px-6 py-2 rounded-full font-semibold hover:bg-opacity-80 transition-colors duration-200 mx-auto"
            >
              Play Now
            </button>
          </div>
        </motion.div>
      </AnimatePresence>
      {featuredSounds.length > 1 && (
        <>
          <button
            onClick={prevSound}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-colors duration-200"
          >
            <ChevronLeft size={24} />
          </button>
          <button
            onClick={nextSound}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-colors duration-200"
          >
            <ChevronRight size={24} />
          </button>
        </>
      )}
    </div>
  );
};

export default FeaturedSoundsCarousel;