import React, { useState, useRef, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { createSound, fetchSoundTypes, fetchTags, fetchGenres, fetchInstruments, analyzeAudio } from '../api/APIManager'; 
import { CustomMultiSelect } from '../components/CustomMultiSelect'; 
import SpinnerFull from '../components/SpinnerFull';


const VALID_STATUS = ["active", "inactive"];
const VALID_KEYS = [
    "C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "Bb", "B",
    "C Major", "Db Major", "D Major", "Eb Major", "E Major", "F Major",
    "Gb Major", "G Major", "Ab Major", "A Major", "Bb Major", "B Major",
    "C Minor", "Db Minor", "D Minor", "Eb Minor", "E Minor", "F Minor",
    "Gb Minor", "G Minor", "Ab Minor", "A Minor", "Bb Minor", "B Minor"
];

const convertSharpToFlat = (key) => {
  const sharpToFlatMap = {
      'C#': 'Db',
      'D#': 'Eb',
      'F#': 'Gb',
      'G#': 'Ab',
      'A#': 'Bb'
  };

  for (const [sharp, flat] of Object.entries(sharpToFlatMap)) {
      if (key.includes(sharp)) {
          return key.replace(sharp, flat);
      }
  }

  return key;
};

const Upload = () => {
    const { setCurrentPlayingSound, setIsPlaying: setGlobalIsPlaying } = useOutletContext();
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [name, setName] = useState('');
    const [fileName, setFileName] = useState('');
    const [description, setDescription] = useState('');
    const [typeId, setTypeId] = useState('');
    const [bpm, setBpm] = useState('');
    const [key, setKey] = useState('');
    const [status, setStatus] = useState('');
    const [costInCredits, setCostInCredits] = useState('1');
    const [isUploading, setIsUploading] = useState(false);
    const [audioPreview, setAudioPreview] = useState(null);
    const [isLocalPlaying, setIsLocalPlaying] = useState(false);
    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);  
    const [soundTypes, setSoundTypes] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [availableGenres, setAvailableGenres] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [availableInstruments, setAvailableInstruments] = useState([]);
    const [selectedInstruments, setSelectedInstruments] = useState([]);
    const [analyzedData, setAnalyzedData] = useState(null);
    const [isAnalyzing, setIsAnalyzing] = useState(false);

    useEffect(() => {
      const loadData = async () => {
        const types = await fetchSoundTypes();
        setSoundTypes(types);
        
        const fetchedTags = await fetchTags();
        setAvailableTags(fetchedTags);
        const fetchedGenres = await fetchGenres();
        setAvailableGenres(fetchedGenres);
        const fetchedInstruments = await fetchInstruments();
        setAvailableInstruments(fetchedInstruments);
      };
      loadData();

        return () => {
          if (audioPreview) {
            URL.revokeObjectURL(audioPreview);
          }
          if (imagePreview) {
            URL.revokeObjectURL(imagePreview);
          }
        };
      }, [audioPreview, imagePreview]);

      const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if (selectedFile) {
            setFileName(selectedFile.name);
            const fileName = selectedFile.name.split('.').slice(0, -1).join('.');
            setName(fileName);
            const previewUrl = URL.createObjectURL(selectedFile);
            setAudioPreview(previewUrl);
            setIsLocalPlaying(false);

            setIsAnalyzing(true);
            try {
                // Analyze the audio file using the function from APIManager
                const analysis = await analyzeAudio(selectedFile);
                setAnalyzedData(analysis);
                setBpm(Math.round(analysis.bpm).toString());
                
                // Convert the key to flat if it's sharp, and combine with scale
                const convertedKey = convertSharpToFlat(analysis.key);
                const fullKey = `${convertedKey} ${analysis.scale.charAt(0).toUpperCase() + analysis.scale.slice(1)}`;
                
                // Check if the converted key is in the list of valid keys
                if (VALID_KEYS.includes(fullKey)) {
                    setKey(fullKey);
                } else {
                    console.warn(`Invalid key: ${fullKey}. Setting to empty string.`);
                    setKey('');
                }
                
                // Set genres
                const analyzedGenres = analysis.genres.split(', ').map(genre => ({ name: genre }));
                setSelectedGenres(analyzedGenres);

                // Set instruments
                const analyzedInstruments = analysis.instruments.map(instrument => ({ name: instrument }));
                setSelectedInstruments(analyzedInstruments);

                // Set tags (using moods)
                const analyzedTags = analysis.moods.split(', ').map(mood => ({ name: mood }));
                setSelectedTags(analyzedTags);
            } catch (error) {
                console.error('Error analyzing audio:', error);
                // Handle the error (e.g., show an error message to the user)
            } finally {
                setIsAnalyzing(false);
            }
        }
    };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    if (selectedImage) {
      const previewUrl = URL.createObjectURL(selectedImage);
      setImagePreview(previewUrl);
    }
  };

  const handleTagChange = (newSelectedTags) => {
    setSelectedTags(newSelectedTags);
};


const handleInstrumentsChange = (newSelectedInstruments) => {
  setSelectedInstruments(newSelectedInstruments);
};

const handleGenresChange = (newSelectedGenres) => {
  setSelectedGenres(newSelectedGenres);
};

const handleTypeChange = (newSelectedTypes) => {
  // Assuming you want to select only one type
  setTypeId(newSelectedTypes.length > 0 ? newSelectedTypes[0].id : '');
};
  const togglePlayPause = () => {
    if (audioPreview) {
      if (isLocalPlaying) {
        setGlobalIsPlaying(false);
      } else {
        setCurrentPlayingSound({ id: 'preview', audio_preview: audioPreview, name: name || 'Preview' });
        setGlobalIsPlaying(true);
      }
      setIsLocalPlaying(!isLocalPlaying);
    }
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    
    try {
      const soundData = {
        file,
        fileName,
        image,
        name,
        description,
        tags: selectedTags,
        genres: selectedGenres,
        instruments: selectedInstruments,
        typeId,
        bpm,
        key,
        costInCredits,
        typeId,
        status
      };
      
      const response = await createSound(soundData);
    
      // Handle successful upload
    //   alert('Sound uploaded successfully!');

      navigate('/');
      window.location.reload();

      // Reset form
      setFile(null);
      setImage(null);
      setImagePreview(null);
      setName('');
      setFileName('');
      setDescription('');
      setSelectedTags('');
      setTypeId('');
      setBpm('');
      setKey('');
      setCostInCredits('');
      setAudioPreview(null);
      setIsLocalPlaying(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      if (imageInputRef.current) {
        imageInputRef.current.value = '';
      }
    } catch (error) {
      // Handle error
      alert('Error uploading sound. Please try again. ' +  error);
      console.error('Error uploading sound:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const inputClass = "mt-1 block w-full rounded-md bg-bg-secondary border-gray-600 shadow-sm focus:border-accent-start focus:ring focus:ring-accent-start focus:ring-opacity-50 h-12 px-4 py-2";
  
  const selectClass = "appearance-none w-full bg-bg-secondary text-text-primary rounded-md px-4 py-2 pr-8 focus:outline-none focus:ring-2 focus:ring-accent-start h-12";
  const selectWrapperClass = "relative";
  const selectArrowClass = "absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none";

  return (
    <div className="p-4 sm:p-8 max-w-2xl mx-auto mt-6">
      <h1 className="text-3xl font-bold mb-6">Upload Your Sound</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex items-center space-x-4">
          <div className="flex-grow">
            <label htmlFor="file" className="block text-sm font-medium text-text-secondary mb-2">
              Choose Sound File
            </label>
            <input
              type="file"
              id="file"
              ref={fileInputRef}
              accept=".wav,.mp3"
              onChange={handleFileChange}
              className="block w-full text-sm text-text-secondary
                file:mr-4 file:py-3 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-accent-start file:text-white
                hover:file:bg-accent-end
                h-12
              "
              required
            />
          </div>
          {audioPreview && (
            <button
              type="button"
              onClick={togglePlayPause}
              className="flex-shrink-0 bg-accent-start hover:bg-accent-end text-white font-bold h-12 w-12 rounded-full flex items-center justify-center focus:outline-none"
            >
              {isLocalPlaying ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              )}
            </button>
          )}
        </div>
        {isAnalyzing && (
                    <div className="flex items-center justify-center space-x-2 animate-pulse">
                        <div className="w-8 h-8 bg-accent-start rounded-full"></div>
                        <div className="w-8 h-8 bg-accent-start rounded-full"></div>
                        <div className="w-8 h-8 bg-accent-start rounded-full"></div>
                        <span className="text-lg font-semibold">Analyzing audio...</span>
                    </div>
                )}
        <div>
          <label htmlFor="image" className="block text-sm font-medium text-text-secondary mb-2">
            Upload Image
          </label>
          <input
            type="file"
            id="image"
            ref={imageInputRef}
            accept="image/*"
            onChange={handleImageChange}
            className="block w-full text-sm text-text-secondary
              file:mr-4 file:py-3 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-accent-start file:text-white
              hover:file:bg-accent-end
              h-12
            "
            required
          />
        </div>
        {imagePreview && (
          <div className="mt-2">
            <img src={imagePreview} alt="Preview" className="max-w-full h-auto max-h-48 rounded" />
          </div>
        )}
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-text-secondary mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={inputClass}
            required
          />
        </div>
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-text-secondary mb-2">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="4"
            className={`${inputClass} h-32 py-3`}
          ></textarea>
        </div>
                   
        <CustomMultiSelect
          options={availableGenres}
          value={selectedGenres}
          onChange={handleGenresChange}
          placeholder="Select genres..."
          label="Genres"
          isMulti={true}
        />
        <CustomMultiSelect
          options={availableInstruments}
          value={selectedInstruments}
          onChange={handleInstrumentsChange}
          placeholder="Select Instruments..."
          label="Instruments"
          isMulti={true}
        />
        <CustomMultiSelect
          options={availableTags}
          value={selectedTags}
          onChange={handleTagChange}
          placeholder="Select tags..."
          label="Tags"
          isMulti={true}
        />
        <CustomMultiSelect
          options={soundTypes}
          value={typeId ? [soundTypes.find(type => type.id === typeId)] : []}
          onChange={handleTypeChange}
          placeholder="Select a sound type..."
          label="Sound Type"
          isMulti={false}
          required={true}
        />
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="bpm" className="block text-sm font-medium text-text-secondary mb-2">
              BPM
            </label>
            <input
              type="number"
              id="bpm"
              value={bpm}
              onChange={(e) => setBpm(e.target.value)}
              className={inputClass}
              min="0"
            />
          </div>
          <div className={selectWrapperClass}>
            <label htmlFor="key" className="block text-sm font-medium text-text-secondary mb-2">
              Key
            </label>
            <select
              id="key"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              className={selectClass}
              required
            >
              <option value="">Select a key</option>
              {VALID_KEYS.map((validKey) => (
                <option key={validKey} value={validKey}>
                  {validKey}
                </option>
              ))}
            </select>
            <div className={selectArrowClass}>
              <svg className="w-4 h-4 text-text-secondary" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>
        <div className={selectWrapperClass}>
          <label htmlFor="status" className="block text-sm font-medium text-text-secondary mb-2">
            Status
          </label>
          <select
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className={selectClass}
          >
            {VALID_STATUS.map((validStatus) => (
              <option key={validStatus} value={validStatus}>
                {validStatus}
              </option>
            ))}
          </select>
          <div className={selectArrowClass}>
            <svg className="w-4 h-4 text-text-secondary" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
        <div>
          <label htmlFor="costInCredits" className="block text-sm font-medium text-text-secondary mb-2">
            Cost in Credits
          </label>
          <input
            type="number"
            id="costInCredits"
            value={costInCredits}
            onChange={(e) => setCostInCredits(e.target.value)}
            className={inputClass}
            min="0"
          />
        </div>
        <div>
          <button
            type="submit"
            disabled={isUploading}
            className={`w-full flex justify-center py-3 px-4 border border-transparent mb-28 rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-accent-start to-accent-end hover:from-accent-end hover:to-accent-start focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-start ${
              isUploading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isUploading ? 'Uploading...' : 'Upload Sound'}
          </button>
        </div>
      </form>

    </div>
  );
};

export default Upload;