import React, { useEffect } from 'react';

import SoundBrowser from '../components/SoundBrowser';


const ExploreIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 10.9c-.61 0-1.1.49-1.1 1.1s.49 1.1 1.1 1.1c.61 0 1.1-.49 1.1-1.1s-.49-1.1-1.1-1.1zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm2.19 12.19L6 18l3.81-8.19L18 6l-3.81 8.19z"/>
  </svg>
);

const Home = () => {
  useEffect(() => {
  }, []);

  return <SoundBrowser cacheKey="homeSounds" showFeatured={true} title="Explore" icon={ExploreIcon}  />;
};

export default Home;
