import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { JUCESend, JUCEReceive, isJUCE } from '../context/JUCE';
import { getDownloadURL } from '../api/APIManager';
import SpinnerFull from './SpinnerFull';

const PlayIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M8 5v14l11-7z" />
  </svg>
);

const PauseIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M6 4h4v16H6zM14 4h4v16h-4z" />
  </svg>
);

const AudioPlayer = ({ sound, isPlaying, onPlayPause, onEnded }) => {
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const [isWaveSurferReady, setIsWaveSurferReady] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleJUCEReceive = ({ eventName, eventData }) => {
      if (eventName === 'dragExportComplete') {
        setIsDragging(false);
      }
      if (eventName === 'audioPlayerPosition') {
        if (waveSurferRef.current) {
          waveSurferRef.current.seekTo(eventData);
          setCurrentTime(eventData * duration);
        }
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);

    return () => {
      JUCEReceive.off('JUCEReceive', handleJUCEReceive);
    };
  }, []);

  useEffect(() => {
    if (waveSurferRef.current) {
      try {
        waveSurferRef.current.destroy();
      } catch (error) {
        console.error("Error while destroying WaveSurfer:", error);
      }
    }
  
    const initWaveSurfer = () => {
      if (!sound || !sound.audio_preview) return;
  
      // Create temporary DOM elements to apply Tailwind text classes and extract colors
      const primaryColorElement = document.createElement('div');
      primaryColorElement.className = 'text-text-primary hidden';
      document.body.appendChild(primaryColorElement);
      const secondaryColorElement = document.createElement('div');
      secondaryColorElement.className = 'text-text-secondary hidden';
      document.body.appendChild(secondaryColorElement);
  
      // Extract the computed colors
      const primaryColor = getComputedStyle(primaryColorElement).color;
      const secondaryColor = getComputedStyle(secondaryColorElement).color;
  
      // Clean up the temporary elements
      document.body.removeChild(primaryColorElement);
      document.body.removeChild(secondaryColorElement);
  
      waveSurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: secondaryColor, 
        progressColor: primaryColor,
        height: 64,
        responsive: true,
        barWidth: 2,
        cursorWidth: 1,
        hideScrollbar: true,
        backend: 'MediaElement',
      });
  
      waveSurferRef.current.load(sound.audio_preview);
  
      waveSurferRef.current.on('ready', () => {
        setDuration(waveSurferRef.current.getDuration());
        setIsWaveSurferReady(true);
        if (isPlaying && !isJUCE()) waveSurferRef.current.play();
      });
  
      waveSurferRef.current.on('audioprocess', () => {
        if (!isJUCE()) setCurrentTime(waveSurferRef.current.getCurrentTime());
      });
  
      waveSurferRef.current.on('finish', onEnded);
    };
  
    const timer = setTimeout(initWaveSurfer, 100);
  
    return () => {
      clearTimeout(timer);
      if (waveSurferRef.current) {
        try {
          waveSurferRef.current.destroy();
        } catch (error) {
          console.error("Error while destroying WaveSurfer on cleanup:", error);
        }
      }
    };
  }, [sound, onEnded, isPlaying]);

  useEffect(() => {
    if (isWaveSurferReady && !isJUCE() && waveSurferRef.current) {
      if (isPlaying) {
        waveSurferRef.current.play();
      } else {
        waveSurferRef.current.pause();
      }
    }
  }, [isPlaying, isWaveSurferReady]);

  const handleSeek = (e) => {
    if (waveSurferRef.current && !isJUCE()) {
      const seekTime = (e.nativeEvent.offsetX / e.target.clientWidth) * duration;
      waveSurferRef.current.seekTo(seekTime / duration);
      setCurrentTime(seekTime);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleDragStart = async (e) => {
    if (isJUCE() && sound && sound.is_purchased) {
      e.preventDefault();
      setIsDragging(true);
      try {
        const downloadUrl = await getDownloadURL(sound.id);
        const soundWithUrl = { ...sound, download_url: downloadUrl };
        JUCESend('dragExport', soundWithUrl);
      } catch (error) {
        console.error('Error getting download URL:', error);
        JUCESend('dragExport', sound);
      }
    }
  };

  const playerHeight = sound && (isPlaying || isWaveSurferReady) ? '126px' : '0px';
  const bottomPosition = (isWaveSurferReady && isJUCE()) ? '56px' : '0px';

  if (!sound) {
    return null;
  }

  if (isDragging) {
    return <SpinnerFull message="Processing audio, please wait..." />;
  }

  return (
    <div 
      className="fixed left-0 right-0 bg-bg-secondary p-4 z-10 border-t border-black border-opacity-25 transition-all duration-300 ease-in-out"
      style={{ height: playerHeight, overflow: 'hidden', bottom: bottomPosition }}
    >
      <div className="flex items-center space-x-4">
        <img 
          src={sound.image || sound.image2 || '/default-sound-image.jpg'} 
          alt={sound.name} 
          className="w-16 h-16 object-cover rounded flex-shrink-0"
        />
        <div className="flex-grow overflow-hidden">
          <div className="text-sm font-semibold text-text-primary truncate mb-2">{sound.name}</div>
          <div className="flex items-center space-x-2">
            <button
              onClick={onPlayPause}
              className="bg-gradient-to-r from-accent-start to-accent-end text-white p-2 rounded-full hover:opacity-90 flex items-center justify-center flex-shrink-0"
            >
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </button>
            <div 
              ref={waveformRef} 
              className="flex-grow h-[64px] cursor-pointer"
              onClick={handleSeek}
              draggable={isJUCE() && sound.is_purchased}
              onDragStart={handleDragStart}
            ></div>
            <div className="text-xs text-text-secondary whitespace-nowrap">
              {formatTime(currentTime)} / {formatTime(duration)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;