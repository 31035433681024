import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../api/APIConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(localStorage.getItem('access_token'));

  const setAuthHeader = useCallback((token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_id');
    localStorage.clear();
    setAuthHeader(null);
    setIsAuthenticated(false);
    setUser(null);
    setAuthToken(null);
  }, [setAuthHeader]);

  const refreshToken = useCallback(async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) throw new Error('No refresh token available');

      const response = await axios.post(API_ENDPOINTS.REFRESH_TOKEN, { refresh_token: refreshToken });
      const { access_token } = response.data;

      localStorage.setItem('access_token', access_token);
      setAuthToken(access_token);
      setAuthHeader(access_token);

      return access_token;
    } catch (error) {
      console.error('Failed to refresh token:', error);
      logout();
      throw error;
    }
  }, [setAuthHeader, logout]);

  const refreshUserData = useCallback(async () => {
    const userId = localStorage.getItem('user_id');

    try {
      if (!userId) throw new Error('No user ID available');

      const response = await axios.get(`${API_ENDPOINTS.USERS}/${userId}`);
      setUser(response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to refresh user data:', error);
      if (error.response && error.response.status === 401) {
        try {
          await refreshToken();
          // Retry the user fetch with the new token
          const retryResponse = await axios.get(`${API_ENDPOINTS.USERS}/${userId}`);
          setUser(retryResponse.data);
          return retryResponse.data;
        } catch (refreshError) {
          logout();
          throw refreshError;
        }
      } else {
        throw error;
      }
    }
  }, [refreshToken, logout]);

  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    const userId = localStorage.getItem('user_id');
    if (token && userId) {
      try {
        setAuthHeader(token);
        const response = await axios.get(`${API_ENDPOINTS.USERS}/${userId}`);
        setUser(response.data);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Authentication check failed:', error);
        if (error.response && error.response.status === 401) {
          try {
            await refreshToken();
            // Retry the user fetch with the new token
            const retryResponse = await axios.get(`${API_ENDPOINTS.USERS}/${userId}`);
            setUser(retryResponse.data);
            setIsAuthenticated(true);
          } catch (refreshError) {
            logout();
          }
        } else {
          logout();
        }
      }
    } else {
      logout();
    }
    setIsLoading(false);
  }, [setAuthHeader, refreshToken, logout]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const loginAndLoadUser = async (username, password) => {
    setIsLoading(true);
    try {
      const loginResponse = await axios.post(API_ENDPOINTS.SIGN_IN, { username, password });
      const { soundware_id, tokens } = loginResponse.data;
      localStorage.setItem('access_token', tokens.access_token);
      localStorage.setItem('refresh_token', tokens.refresh_token);
      localStorage.setItem('user_id', soundware_id);
      
      setAuthToken(tokens.access_token);
      setAuthHeader(tokens.access_token);
      
      setUser(loginResponse.data);
      setIsAuthenticated(true);
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      isLoading, 
      user, 
      authToken, 
      checkAuth, 
      loginAndLoadUser, 
      logout, 
      refreshToken,
      refreshUserData
    }}>
      {children}
    </AuthContext.Provider>
  );
};