import React, { useState, useRef, useEffect } from 'react';

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>
);

const DropdownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
    </svg>
);

export const CustomMultiSelect = ({ options, value, onChange, placeholder, isMulti = true, required = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    const safeValue = Array.isArray(value) ? value : [];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            setSearchTerm('');
        }
    }, [isOpen]);

    const filteredOptions = options.filter(option =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const toggleOption = (option) => {
        if (isMulti) {
            const newValue = safeValue.some(v => v.id === option.id)
                ? safeValue.filter(v => v.id !== option.id)
                : [...safeValue, option];
            onChange(newValue);
        } else {
            onChange([option]);
            setIsOpen(false);
        }
        setSearchTerm('');
    };

    const removeOption = (optionToRemove, event) => {
        event.stopPropagation();
        const newValue = safeValue.filter(v => v.id !== optionToRemove.id);
        onChange(newValue);
    };

    const renderSelectedItems = () => {
        if (safeValue.length === 0) {
            return <span className="text-gray-400 text-sm">{placeholder}</span>;
        }

        return (
            <div className="flex items-center space-x-1 overflow-hidden">
                {safeValue.slice(0, 2).map(item => (
                    <span key={item.id} className="bg-accent-start text-white rounded-full px-2 py-0.5 text-xs whitespace-nowrap flex items-center">
                        {item.name}
                        <button
                            className="ml-1 text-white hover:text-red-500 focus:outline-none"
                            onClick={(e) => removeOption(item, e)}
                        >
                            ×
                        </button>
                    </span>
                ))}
                {safeValue.length > 2 && (
                    <span className="text-gray-400 text-xs">+{safeValue.length - 2}</span>
                )}
            </div>
        );
    };

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="relative w-full" ref={dropdownRef}>
            <div
                className={`
                    bg-bg-secondary border border-accent-start hover:border-accent-end 
                    rounded-full px-4 py-2 cursor-pointer h-9 flex items-center justify-between 
                    text-sm transition-all duration-300 ease-in-out
                    ${isOpen ? 'ring-2 ring-accent-end/50' : ''}
                    ${required && safeValue.length === 0 ? 'border-red-500' : ''}
                `}
                onClick={handleToggleDropdown}
            >
                <div className="flex-grow overflow-hidden">
                    {renderSelectedItems()}
                </div>
                <div className="flex-shrink-0 ml-2">
                    <DropdownIcon />
                </div>
            </div>
            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-bg-secondary border border-accent-start rounded-md shadow-lg overflow-hidden">
                    <div className="relative">
                        <input
                            type="text"
                            className="w-full p-2 pl-8 border-b border-accent-start text-sm bg-bg-secondary text-text-primary placeholder-text-secondary"
                            placeholder={`Search ${placeholder.toLowerCase()}...`}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <div className="absolute left-2 top-1/2 transform -translate-y-1/2">
                            <SearchIcon />
                        </div>
                    </div>
                    <div className="max-h-48 overflow-y-auto">
                        {filteredOptions.map(option => (
                            <div
                                key={option.id}
                                className={`
                                    p-2 hover:bg-accent-start hover:text-white cursor-pointer text-sm
                                    transition-colors duration-150 ease-in-out
                                    ${safeValue.some(v => v.id === option.id) ? 'bg-accent-start text-white' : 'text-text-primary'}
                                `}
                                onClick={() => toggleOption(option)}
                            >
                                {option.name}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {required && safeValue.length === 0 && (
                <p className="text-red-500 text-xs mt-1">This field is required</p>
            )}
        </div>
    );
};

export default CustomMultiSelect;