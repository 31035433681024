import React, { useState, useRef, useEffect } from 'react';
import { JUCESend, isJUCE, JUCEReceive } from '../context/JUCE';

const scales = [
    { major: 'C', minor: 'A' },
    { major: 'Db', minor: 'Bb' },
    { major: 'D', minor: 'B' },
    { major: 'Eb', minor: 'C' },
    { major: 'E', minor: 'Db' },
    { major: 'F', minor: 'D' },
    { major: 'Gb', minor: 'Eb' },
    { major: 'G', minor: 'E' },
    { major: 'Ab', minor: 'F' },
    { major: 'A', minor: 'Gb' },
    { major: 'Bb', minor: 'G' },
    { major: 'B', minor: 'Ab' }
];

const LockIcon = ({ locked }) => (
  <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
    {locked ? (
      <path d="M12 1C8.676 1 6 3.676 6 7v2H4v14h16V9h-2V7c0-3.324-2.676-6-6-6zm0 2c2.276 0 4 1.724 4 4v2H8V7c0-2.276 1.724-4 4-4z" />
    ) : (
      <path d="M12 1C8.676 1 6 3.676 6 7v2h4V7c0-1.276.724-2 2-2s2 .724 2 2v2h2V7c0-3.324-2.676-6-6-6zm-8 8v14h16V9H4zm8 7c1.276 0 2 .724 2 2s-.724 2-2 2-2-.724-2-2 .724-2 2-2z" />
    )}
  </svg>
);

const CustomSelect = ({ value, onChange, options, disabled = false, width = '120px' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={selectRef} style={{ width }}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
        className={`w-full bg-bg-secondary border border-accent-start hover:border-accent-end text-text-primary rounded-md px-3 py-1.5 text-xs focus:outline-none focus:ring-1 focus:ring-accent-end/50 transition-all duration-300 ease-in-out flex items-center justify-between ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        style={{ height: '36px' }}
      >
        <span>{options.find(opt => opt.value === value)?.label}</span>
        <svg className="w-4 h-4 text-text-secondary ml-1" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute bottom-full left-0 w-full mb-1 bg-bg-secondary border border-accent-start rounded-md shadow-lg overflow-hidden z-10">
          {options.map((option) => (
            <div
              key={option.value}
              className={`p-2 hover:bg-accent-start hover:text-white cursor-pointer text-xs transition-colors duration-150 ease-in-out ${value === option.value ? 'bg-accent-start text-white' : 'text-text-primary'}`}
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const AudioControls = () => {
  const [selectedScale, setSelectedScale] = useState(0);
  const [isKeyLocked, setIsKeyLocked] = useState(false);
  const [tempo, setTempo] = useState(120);
  const [octave, setOctave] = useState(0);
  const [speed, setSpeed] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [isBpmLocked, setIsBpmLocked] = useState(false);
  const dragStartY = useRef(0);
  const dragStartTempo = useRef(0);

  const handleSpeedChange = (value) => {
    setSpeed(parseFloat(value));
    JUCESend('speedChanged', {value});
  };

  const handleOctaveChange = (value) => {
    setOctave(parseInt(value));
    JUCESend('octaveChanged', {value});
  };

  const handleScaleChange = (value) => {
    if (!isKeyLocked) {
      setSelectedScale(parseInt(value));
      JUCESend('scaleChanged', {value});
    }
  };

  const handleTempoChange = (value) => {
    if (!isBpmLocked) {
      const tempo = Math.max(40, Math.min(240, value));
      setTempo(tempo);
      JUCESend('tempoChanged', {value: tempo});
    }
  };

  const handleMouseDown = (e) => {
    if (!isBpmLocked) {
      setIsDragging(true);
      dragStartY.current = e.clientY;
      dragStartTempo.current = tempo;
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && !isBpmLocked) {
      const dragDelta = dragStartY.current - e.clientY;
      const tempoDelta = Math.round(dragDelta / 2);
      handleTempoChange(dragStartTempo.current + tempoDelta);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleJUCEReceive = ({ eventName, eventData }) => {
      console.log(eventData);
      if (eventName === 'bpm') {
        setTempo(parseInt(eventData));
        setIsBpmLocked(true);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
      
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      JUCEReceive.off('JUCEReceive', handleJUCEReceive);
    };
  }, [isDragging]);

  return (
    <div className="bg-bg-secondary h-full flex justify-end items-center px-4 space-x-2">
      <CustomSelect
        value={speed}
        onChange={handleSpeedChange}
        options={[
          { value: 0.5, label: 'Speed: 0.5x' },
          { value: 1, label: 'Speed: 1x' },
          { value: 2, label: 'Speed: 2x' }
        ]}
        width="120px"
      />

      <CustomSelect
        value={octave}
        onChange={handleOctaveChange}
        options={[
          { value: 1, label: 'Octave: +1' },
          { value: 0, label: 'Octave: 0' },
          { value: -1, label: 'Octave: -1' }
        ]}
        width="120px"
      />

      <div className="flex items-center h-9">
        <div 
          className={`flex items-center justify-center bg-bg-primary border border-accent-start text-text-primary rounded-l-md h-full px-3 ${isBpmLocked ? 'cursor-not-allowed' : 'cursor-ns-resize'} select-none`}
          onMouseDown={handleMouseDown}
          width="190px"

        >
          <span className="text-sm font-medium mr-1 w-6 text-right">{tempo}</span>
          <span className="text-xs text-text-secondary">BPM</span>
        </div>
        <button
          onClick={() => handleTempoChange(tempo + 1)}
          disabled={isBpmLocked}
          className={`h-full w-6 bg-bg-primary border-t border-b border-accent-start text-text-primary ${isBpmLocked ? 'cursor-not-allowed opacity-50' : 'hover:bg-accent-start hover:text-white'} focus:ring-1 focus:ring-accent-start transition-colors duration-200 text-xs`}
        >
          ▲
        </button>
        <button
          onClick={() => handleTempoChange(tempo - 1)}
          disabled={isBpmLocked}
          className={`h-full w-6 bg-bg-primary border border-accent-start text-text-primary ${isBpmLocked ? 'cursor-not-allowed opacity-50' : 'hover:bg-accent-start hover:text-white'} focus:ring-1 focus:ring-accent-start transition-colors duration-200 text-xs rounded-r-md`}
       >
          ▼
        </button>
      </div>

      <div className="flex items-center">
        <CustomSelect
          value={selectedScale}
          onChange={handleScaleChange}
          options={scales.map((scale, index) => ({
            value: index,
            label: `${scale.major} Major | ${scale.minor} Minor`
          }))}
          disabled={isKeyLocked}
          width="160px"
        />
        <button
          onClick={() => setIsKeyLocked(!isKeyLocked)}
          className={`ml-1 h-9 w-9 ${
            isKeyLocked ? 'bg-accent-start text-white' : 'bg-bg-primary text-text-primary'
          } rounded-md hover:bg-accent-end focus:ring-1 focus:ring-accent-start transition-colors duration-200 flex items-center justify-center border border-accent-start`}
        >
          <LockIcon locked={isKeyLocked} />
        </button>
      </div>
    </div>
  );
};

export default AudioControls;