import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_SOUND_IMAGE } from '../api/APIConfig';
import Spinner from './Spinner';
import { isJUCE, JUCESend } from '../context/JUCE';

const PlayIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="white">
    <path d="M8 5v14l11-7z" />
  </svg>
);

const PauseIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="white">
    <path d="M6 4h4v16H6zM14 4h4v16h-4z" />
  </svg>
);

const LikeIcon = ({ filled }) => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill={filled ? "currentColor" : "none"} stroke="currentColor" strokeWidth="2">
    <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
  </svg>
);

const DownloadIcon = () => {
  const [strokeColor, setStrokeColor] = useState('#000');

  useEffect(() => {
    const tempElement = document.createElement('div');
    tempElement.className = 'text-text-primary hidden';
    document.body.appendChild(tempElement);
    const primaryColor = getComputedStyle(tempElement).color;
    setStrokeColor(primaryColor);
    document.body.removeChild(tempElement);
  }, []);

  return (
    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke={strokeColor} strokeWidth="2">
      <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const DragIcon = ({ onDragStart, onDragEnd, onMouseEnter, onMouseLeave }) => (
  <div 
    className="flex flex-col items-center justify-center cursor-grab active:cursor-grabbing w-full h-full"
    draggable="true"
    onDragStart={onDragStart}
    onDragEnd={onDragEnd}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div className="flex flex-col items-center">
      <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
        <rect x="0" y="5" width="40" height="2" rx="1" />
        <rect x="0" y="11" width="40" height="2" rx="1" />
        <rect x="0" y="17" width="40" height="2" rx="1" />
      </svg>
      <span className="hidden sm:inline text-xs mt-1 text-center">Drag</span>
    </div>
  </div>
);

export const SoundCard = ({ 
  sound: initialSound, 
  onLike, 
  onUnlike, 
  onPurchase, 
  onPlay, 
  onDownload, 
  currentPlayingSound,
  isPlaying,
  isPurchasing,
  onDragStart,
  onPurchaseSuccess
}) => {
  const navigate = useNavigate();
  const [sound, setSound] = useState(initialSound);
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const cardRef = useRef(null);
  const imageUrl = sound.image || sound.image2 || DEFAULT_SOUND_IMAGE;
  const hasAudioPreview = !!sound.audio_preview;
  const isCurrentSound = currentPlayingSound && currentPlayingSound.id === sound.id;

  const buttonClass = "h-9 flex flex-col items-center justify-center text-white rounded hover:opacity-80 transition-all duration-200";

  useEffect(() => {
    setSound(initialSound);
  }, [initialSound]);

  const handleDragStart = (e) => {
    if (isJUCE() && sound.is_purchased) {
      e.preventDefault();
      const dragImage = new Image();
      dragImage.src = imageUrl;
      e.dataTransfer.setDragImage(dragImage, 0, 0);
      e.dataTransfer.setData('text/plain', JSON.stringify(sound));
      setIsDragging(true);
      onDragStart(e, sound);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleClick = (e) => {
    if (e.target.closest('button') || e.target.closest('.drag-icon')) return;
    
    if (!isDragging) {
      onPlay(sound);
    }
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleLikeToggle = (e) => {
    e.stopPropagation();
    const newLikedState = !sound.is_liked;
    
    setSound(prevSound => ({
      ...prevSound,
      is_liked: newLikedState
    }));

    if (newLikedState) {
      onLike(sound.id).catch(() => {
        setSound(prevSound => ({
          ...prevSound,
          is_liked: !newLikedState
        }));
      });
    } else {
      onUnlike(sound.id).catch(() => {
        setSound(prevSound => ({
          ...prevSound,
          is_liked: !newLikedState
        }));
      });
    }
  };

  const handlePurchase = (e) => {
    e.stopPropagation();
    onPurchase(sound.id).then(() => {
      setSound(prevSound => ({
        ...prevSound,
        is_purchased: true
      }));
      if (onPurchaseSuccess) {
        onPurchaseSuccess(sound.id);
      }
    }).catch((error) => {
      console.error('Purchase failed:', error);
    });
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    setIsDownloading(true);
    onDownload(sound.id)
      .then(() => {
        setIsDownloading(false);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.error('Download failed:', error);
      });
  };

  const handleArtistClick = (e) => {
    e.stopPropagation();
    navigate(`/profile/${sound.owner.id}`);
  };

  const renderActionButton = () => {
    if (isPurchasing) {
      return (
        <div className="w-14 sm:w-20 flex items-center justify-center">
          <Spinner />
        </div>
      );
    }

    if (sound.is_purchased) {
      if (isJUCE()) {
        return (
          <div className="w-14 sm:w-20 h-9 drag-icon">
            <DragIcon 
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </div>
        );
      } else {
        return (
          <button 
            onClick={handleDownload}
            className={`${buttonClass} w-14 sm:w-20`}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <Spinner />
            ) : (
              <>
                <DownloadIcon />
                <span className="hidden sm:inline text-xs mt-1 text-text-primary">Download</span>
              </>
            )}
          </button>
        );
      }
    } else {
      return (
        <button 
          onClick={handlePurchase}
          className={`${buttonClass} w-16 sm:p-0 p-5 sm:w-20 bg-bg-primary`}
        >
          {sound.cost_in_credits ? (
            <>
              <span className="sm:hidden text-xs font-semibold text-text-primary">{sound.cost_in_credits}</span>
              <span className="sm:hidden text-xs text-text-primary" >Credits</span>
              <span className="hidden sm:inline text-xs text-text-primary">{sound.cost_in_credits} Credits</span>
            </>
          ) : (
            'Get'
          )}
        </button>
      );
    }
  };

 
  return (
    <div 
      ref={cardRef}
      className={`
        relative bg-bg-secondary rounded-lg p-3 flex items-center h-24 
        transition-all duration-300 ease-in-out
        ${isHovered || isCurrentSound ? 'scale-[1.005] z-10' : 'scale-100 z-0'}
        ${isCurrentSound ? 'ring-1 ring-white ring-opacity-20' : ''}
        shadow-[0_2px_10px_rgba(0,0,0,0.1),_0_1px_3px_rgba(0,0,0,0.06)] 
        hover:shadow-[0_3px_12px_rgba(0,0,0,0.1),_0_2px_4px_rgba(0,0,0,0.06)]
      `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div 
        className={`
          relative w-16 h-16 flex-shrink-0 mr-3 rounded overflow-hidden cursor-pointer
          transition-all duration-300 ease-in-out
          ${isHovered || isCurrentSound ? 'brightness-110' : 'brightness-100'}
        `}
        onClick={handleClick}
      >
        <img src={imageUrl} alt={`${sound.name} artwork`} className="w-full h-full object-cover" draggable="false" />
        {hasAudioPreview && (
          <div 
            className={`
              absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 
              transition-opacity duration-300 ease-in-out
              ${isHovered || isCurrentSound ? 'opacity-100' : 'opacity-0'}
            `}
          >
            {isCurrentSound && isPlaying ? <PauseIcon /> : <PlayIcon />}
          </div>
        )}
      </div>
      <div className="flex-grow grid grid-cols-2 md:grid-cols-6 gap-2 overflow-hidden cursor-pointer" onClick={handleClick}>
        <div className="col-span-2">
          <div className={`
            font-semibold text-text-primary truncate select-none
            transition-all duration-300 ease-in-out
          `}>
            {sound.name}
          </div>
          <div 
            className="text-text-secondary text-sm truncate select-none cursor-pointer hover:underline"
            onClick={handleArtistClick}
          >
            {sound.owner.username}
          </div>
        </div>
        <div className="hidden md:flex flex-col justify-center items-start">
          {sound.instruments && sound.instruments.length > 0 ? (
            sound.instruments.slice(0, 2).map((instrument, index) => (
              <span key={index} className="bg-white bg-opacity-10 text-text-primary px-2 py-0.5 rounded text-xs select-none mb-1">
                {instrument.name}
              </span>
            ))
          ) : (
            <span className="text-text-secondary text-sm select-none">-</span>
          )}
        </div>
        <div className="hidden md:flex flex-col justify-center items-start">
          <div className="text-text-secondary text-sm truncate select-none">
            {sound.type ? sound.type.name : '-'}
          </div>
        </div>
        <div className="hidden md:flex flex-col justify-center items-start">
          <div className="text-text-secondary text-sm truncate select-none">
            {sound.key || '-'}
          </div>
        </div>
        <div className="hidden md:flex flex-col justify-center items-start">
          <div className="text-text-secondary text-sm truncate select-none">
            {typeof sound.bpm === 'number' && sound.bpm > 0 ? `${sound.bpm} BPM` : '-'}
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-2 z-10 ml-2">
        <button 
          onClick={handleLikeToggle}
          className={`
            p-1.5 rounded-full hover:bg-gray-700
            transition-all duration-300 ease-in-out
            ${isHovered || isCurrentSound ? 'scale-105' : 'scale-100'}
          `}
        >
          <LikeIcon filled={sound.is_liked} />
        </button>
        {renderActionButton()}
      </div>
      {isJUCE() && sound.is_purchased && (
        <div
          className="absolute inset-0 cursor-grab active:cursor-grabbing"
          draggable="true"
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
    </div>
  );
};

export default SoundCard;