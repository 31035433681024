// const API_BASE_URL = 'http://localhost:8080'; // Replace with your actual API base URL
// export const APPLICATION_ID = "0192514d-3027-76b4-938a-40fd83b309ee"; // Local

// const API_BASE_URL = 'https://staging.soundware.io'; // Replace with your actual API base URL
// export const APPLICATION_ID = "01925766-39fd-7546-be8f-50ba53bdb936"; // Staging

const API_BASE_URL = 'https://api.soundware.io'; // Replace with your actual API base URL
export const APPLICATION_ID = "01925766-618c-7ec6-8616-6c470cd42b04"; // Production

const API_VERSION = 'api/v1'; // Replace with your API version
export const API_ENDPOINTS = {
  SIGN_IN: `${API_BASE_URL}/${API_VERSION}/signin`,
  SOUNDS: `${API_BASE_URL}/${API_VERSION}/sounds`,
  USERS: `${API_BASE_URL}/${API_VERSION}/users`,
  UPLOAD_FILE: `${API_BASE_URL}/${API_VERSION}/sounds/upload`,
  SOUND_TYPES: `${API_BASE_URL}/${API_VERSION}/sounds/types`,
  TAGS: `${API_BASE_URL}/${API_VERSION}/tags`,
  INSTRUMENTS: `${API_BASE_URL}/${API_VERSION}/instruments`,
  GENRES: `${API_BASE_URL}/${API_VERSION}/genres`,
  GET_USER:  (userId) => `${API_BASE_URL}/${API_VERSION}/users/${userId}`,
  GET_SOUND: (soundId) => `${API_BASE_URL}/${API_VERSION}/sounds/${soundId}`,
  LIKE_SOUND: (soundId) => `${API_BASE_URL}/${API_VERSION}/sounds/${soundId}/like`,
  UNLIKE_SOUND: (soundId) => `${API_BASE_URL}/${API_VERSION}/sounds/${soundId}/unlike`,
  PURCHASE_SOUND: (soundId) => `${API_BASE_URL}/${API_VERSION}/sounds/${soundId}/purchase`,
  GET_SOUND_DOWNLOAD_URL: (soundId) => `${API_BASE_URL}/${API_VERSION}/sounds/${soundId}/download/request`,
};

// export const DEFAULT_SOUND_IMAGE = 'https://via.placeholder.com/200?text=No+Image'; 
export const DEFAULT_SOUND_IMAGE = 'https://i1.sndcdn.com/avatars-000302284113-fhxnbn-t240x240.jpg'; 


