import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Logo } from './Logo';

const Header = ({ onMenuToggle }) => {
  const { user, logout } = useContext(AuthContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    logout();
    setIsDropdownOpen(false);
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-bg-secondary flex justify-between items-center z-30 border-b border-black border-opacity-10 shadow-[0_4px_20px_-2px_rgba(0,0,0,0.1)] h-16 sm:h-20 transition-all duration-300">
      <div className="flex justify-between items-center w-full px-4 sm:px-6">
        <Link to="/">
          <Logo />
        </Link>
        <div className="flex items-center space-x-4">
          <div className="flex items-center">
            <span className="font-semibold">Credits: {user.credits || 0}</span>
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="ml-4 mr-4 focus:outline-none"
              >
                {user.avatar ? (
                  <img 
                    src={user.avatar} 
                    alt="User Avatar" 
                    className="w-10 h-10 rounded-full object-cover"
                  />
                ) : (
                  <div className="w-10 h-10 rounded-full bg-black flex items-center justify-center text-white font-bold">
                    {user.email.slice(0, 2).toUpperCase()}
                  </div>
                )}
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                  <Link
                    to="/profile/me"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    Profile
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
            <Link
              to="/upload"
              className="bg-gradient-to-r from-accent-start to-accent-end text-white px-4 py-2 rounded hover:opacity-90 hidden sm:block"
            >
              Upload Sound
            </Link>
          </div>
          <button
            className="sm:hidden p-2"
            onClick={onMenuToggle}
            aria-label="Toggle menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;