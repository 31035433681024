import React, { useState, useEffect } from 'react';
import { CustomMultiSelect } from './CustomMultiSelect';
import { fetchTags, fetchGenres, fetchInstruments } from '../api/APIManager';

const VALID_KEYS = [
    "C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab", "A", "Bb", "B",
    "C Major", "Db Major", "D Major", "Eb Major", "E Major", "F Major",
    "Gb Major", "G Major", "Ab Major", "A Major", "Bb Major", "B Major",
    "C Minor", "Db Minor", "D Minor", "Eb Minor", "E Minor", "F Minor",
    "Gb Minor", "G Minor", "Ab Minor", "A Minor", "Bb Minor", "B Minor"
];

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>
);

const ClearIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    </svg>
);

export const SearchRow = ({ onSearch, initialFilters }) => {
  const [filters, setFilters] = useState(initialFilters || {
    key: [],
    tags: [],
    instruments: [],
    genres: [],
    searchText: ''
  });

  const [availableTags, setAvailableTags] = useState([]);
  const [availableGenres, setAvailableGenres] = useState([]);
  const [availableInstruments, setAvailableInstruments] = useState([]);
  const [showAdvanced, setShowAdvanced] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const [fetchedTags, fetchedGenres, fetchedInstruments] = await Promise.all([
        fetchTags(),
        fetchGenres(),
        fetchInstruments()
      ]);
      setAvailableTags(fetchedTags);
      setAvailableGenres(fetchedGenres);
      setAvailableInstruments(fetchedInstruments);
    };
    loadData();
  }, []);

  const handleFilterChange = (filterName, selectedOptions) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: selectedOptions
    }));
  };

  const handleSearchTextChange = (event) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      searchText: event.target.value
    }));
  };

  const handleClearSearch = () => {
    setFilters(prevFilters => ({
      ...prevFilters,
      searchText: ''
    }));
  };

  const handleSearch = () => {
    onSearch(filters);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };

  const keyOptions = VALID_KEYS.map((key, index) => ({ id: index.toString(), name: key }));

  return (
    <div className="bg-bg-primary p-6 rounded-lg">
      <div className="max-w-6xl mx-auto space-y-4">
        <div className="flex items-center space-x-3">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Search..."
              className="w-full bg-bg-secondary border border-accent-start focus:border-accent-end rounded-full px-4 py-2 pl-10 pr-10 text-sm text-text-primary placeholder-text-secondary transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-accent-end/50"
              onChange={handleSearchTextChange}
              onKeyPress={handleKeyPress}
              value={filters.searchText}
            />
            <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
              <SearchIcon />
            </div>
            {filters.searchText && (
              <button
                className="absolute right-3 top-1/2 transform -translate-y-1/2 hover:bg-gray-200 rounded-full p-1 transition-colors duration-200"
                onClick={handleClearSearch}
              >
                <ClearIcon />
              </button>
            )}
          </div>
          <button
            className="bg-gradient-to-r from-accent-start to-accent-end text-white px-6 py-2 rounded-full hover:opacity-90 transition-all duration-300 ease-in-out text-sm font-medium"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
        
        {/* Mobile: Show Advanced button */}
        <div className="lg:hidden">
          <button
            className="w-full bg-bg-secondary border border-accent-start text-text-primary px-4 py-2 rounded-full hover:border-accent-end transition-all duration-300 ease-in-out text-sm"
            onClick={toggleAdvanced}
          >
            {showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
          </button>
        </div>

        {/* Desktop: Always show filters, Mobile: Show when advanced is toggled */}
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 ${showAdvanced ? '' : 'hidden lg:grid'}`}>
          <CustomMultiSelect
            options={keyOptions}
            value={filters.key}
            onChange={(selected) => handleFilterChange('key', selected)}
            placeholder="Key"
            isMulti={true}
          />
          <CustomMultiSelect
            options={availableTags}
            value={filters.tags}
            onChange={(selected) => handleFilterChange('tags', selected)}
            placeholder="Tags"
            isMulti={true}
          />
          <CustomMultiSelect
            options={availableInstruments}
            value={filters.instruments}
            onChange={(selected) => handleFilterChange('instruments', selected)}
            placeholder="Instruments"
            isMulti={true}
          />
          <CustomMultiSelect
            options={availableGenres}
            value={filters.genres}
            onChange={(selected) => handleFilterChange('genres', selected)}
            placeholder="Genres"
            isMulti={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchRow;