import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { fetchSounds, fetchUserById, followUser, unfollowUser } from '../api/APIManager';
import { SoundList } from '../components/SoundList';
import Spinner from '../components/Spinner';
import RevenueChart from '../components/RevenueChart';
import { DEFAULT_SOUND_IMAGE } from '../api/APIConfig';

const UserProfile = () => {
  const { userId } = useParams();
  const { user } = useContext(AuthContext);
  const [profileUser, setProfileUser] = useState(null);
  const [userSounds, setUserSounds] = useState([]);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoadingSounds, setIsLoadingSounds] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalSounds, setTotalSounds] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [credits, setCredits] = useState(0);
  const [cashBalance, setCashBalance] = useState(0);
  const [dateFilter, setDateFilter] = useState('all');
  const [revenueData, setRevenueData] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const pageSize = 10;
  const loadingRef = useRef(null);

  const loadUserData = useCallback(async () => {
    setIsLoadingUser(true);
    setProfileUser(null);
    setTotalSounds(0);
    try {
      let userData;
      if (userId === 'me' || !userId) {
        // Check if user.soundware_id exists, use it if it does, otherwise use user.id
        const idToFetch = user.soundware_id ? user.soundware_id : user.id;
        userData = await fetchUserById(idToFetch);
    } else {
        // If userId is provided and not 'me', use it
        userData = await fetchUserById(userId);
    }
      
      if (!userData) {
        throw new Error('Failed to load user data');
      }
      
      setProfileUser(userData);
      // Remove commas from credits and convert it to a number
      const sanitizedCredits = parseFloat((userData.credits || "0").toString().replace(/,/g, ''));
      setCredits(sanitizedCredits);
      setCashBalance(((sanitizedCredits / 1.20) / 10).toFixed(2));
      
      // Generate fake revenue data
      const fakeRevenueData = Array.from({ length: 7 }, (_, i) => ({
        month: new Date(2024, i, 1).toLocaleString('default', { month: 'short' }),
        revenue: Math.floor(Math.random() * 5000)
      }));
      setRevenueData(fakeRevenueData);
      setTotalRevenue(fakeRevenueData.reduce((sum, item) => sum + item.revenue, 0));
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoadingUser(false);
    }
  }, [userId, user.id]);

  const loadSounds = useCallback(async (newPage, ownerId, isInitialLoad = false) => {
    if (isLoadingMore && !isInitialLoad) return;
    setIsLoadingMore(true);
    try {
      const response = await fetchSounds(newPage, pageSize, { owner: ownerId });
      if (isInitialLoad) {
        setUserSounds(response.items);
      } else {
        setUserSounds(prev => [...prev, ...response.items]);
      }
      setPage(newPage);
      setHasMore(response.items.length === pageSize);
      setTotalSounds(response.total);
    } catch (error) {
      console.error('Error fetching sounds:', error);
    } finally {
      setIsLoadingMore(false);
      setIsLoadingSounds(false);
    }
  }, []);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);

  useEffect(() => {
    if (profileUser) {
      setUserSounds([]);
      setPage(1);
      setHasMore(true);
      setIsLoadingSounds(true);
      setTotalSounds(0);
      loadSounds(1, profileUser.id, true);
    }
  }, [profileUser, loadSounds]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !isLoadingMore) {
          loadSounds(page + 1, profileUser.id);
        }
      },
      { threshold: 1.0 }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [hasMore, isLoadingMore, page, profileUser, loadSounds]);

  const handleFollow = async () => {
    try {
      await followUser(profileUser.id);
      setProfileUser(prev => ({
        ...prev,
        followers_count: prev.followers_count + 1,
        is_followed: true
      }));
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  const handleUnfollow = async () => {
    try {
      await unfollowUser(profileUser.id);
      setProfileUser(prev => ({
        ...prev,
        followers_count: prev.followers_count - 1,
        is_followed: false
      }));
    } catch (error) {
      console.error('Error unfollowing user:', error);
    }
  };

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
    // Simulate filtering data based on the selected date range
    let filteredData;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    switch (e.target.value) {
      case 'week':
        filteredData = revenueData.slice(currentMonth, currentMonth + 1);
        break;
      case 'month':
        filteredData = revenueData.slice(currentMonth, currentMonth + 1);
        break;
      case 'year':
        filteredData = revenueData.slice(0, currentMonth + 1);
        break;
      default:
        filteredData = revenueData;
    }

    setRevenueData(filteredData);
    setTotalRevenue(filteredData.reduce((sum, item) => sum + item.revenue, 0));
  };

  const handleCashOut = () => {
    window.open('https://store.soundware.io/payout', '_blank', 'noopener,noreferrer');
  };

  if (isLoadingUser) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner />
      </div>
    );
  }

  if (!profileUser) {
    return <Navigate to="/404" />;
  }

  const isOwnProfile = userId === 'me' || !userId;

  const VerifiedBadge = () => (
    <svg className="w-4 h-4 ml-1 inline-block" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM15.19 7.39L9.7 12.88C9.31 13.27 8.68 13.27 8.29 12.88L5.81 10.4C5.42 10.01 5.42 9.38 5.81 8.99C6.2 8.6 6.83 8.6 7.22 8.99L9 10.77L13.78 5.99C14.17 5.6 14.8 5.6 15.19 5.99C15.58 6.38 15.58 7.01 15.19 7.39Z" 
        fill="#0095F6"
      />
      <path 
        d="M15.19 7.39L9.7 12.88C9.31 13.27 8.68 13.27 8.29 12.88L5.81 10.4C5.42 10.01 5.42 9.38 5.81 8.99C6.2 8.6 6.83 8.6 7.22 8.99L9 10.77L13.78 5.99C14.17 5.6 14.8 5.6 15.19 5.99C15.58 6.38 15.58 7.01 15.19 7.39Z" 
        fill="white"
      />
    </svg>
  );

  return (
    <div className="bg-bg-primary text-text-primary p-4 sm:p-6">
      <div className="max-w-4xl mx-auto">
        <div className="bg-bg-secondary rounded-lg p-4 sm:p-6 mb-6">
          <div className="flex flex-col sm:flex-row items-center mb-4">
            <img
              src={profileUser.avatar || DEFAULT_SOUND_IMAGE}
              alt={`${profileUser.username}'s avatar`}
              className="w-24 h-24 rounded-full mb-4 sm:mb-0 sm:mr-6"
            />
            <div className="flex-grow text-center sm:text-left">
              <h1 className="text-2xl sm:text-3xl font-bold mb-2 flex items-center justify-center sm:justify-start">
                {profileUser.username}
                {<VerifiedBadge />}
              </h1>
              {isOwnProfile && <p className="text-text-secondary mb-2">{profileUser.email}</p>}
            </div>
            {!isOwnProfile && (
              <button
                onClick={profileUser.is_followed ? handleUnfollow : handleFollow}
                className={`px-4 py-2 rounded mt-4 sm:mt-0 ${
                  profileUser.is_followed
                    ? 'bg-gray-500 hover:bg-gray-600'
                    : 'bg-accent-start hover:bg-accent-end'
                } text-white transition-colors duration-200`}
              >
                {profileUser.is_followed ? 'Unfollow' : 'Follow'}
              </button>
            )}
          </div>
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="bg-bg-primary rounded p-3 text-center">
              <p className="text-xl sm:text-2xl font-bold">{totalSounds}</p>
              <p className="text-xs sm:text-sm text-text-secondary">Sounds</p>
            </div>
            <div className="bg-bg-primary rounded p-3 text-center">
              <p className="text-xl sm:text-2xl font-bold">{profileUser.followers_count || 0}</p>
              <p className="text-xs sm:text-sm text-text-secondary">Followers</p>
            </div>
            <div className="bg-bg-primary rounded p-3 text-center">
              <p className="text-xl sm:text-2xl font-bold">{profileUser.following_count || 0}</p>
              <p className="text-xs sm:text-sm text-text-secondary">Following</p>
            </div>
          </div>
          <p className="text-sm">{profileUser.bio || 'No bio provided.'}</p>
          
          {isOwnProfile && (
            <div className="bg-bg-primary rounded-lg p-4 mt-6">
              <h2 className="text-xl font-bold mb-2">Account Balance</h2>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <p className="text-sm text-text-secondary">Credits</p>
                  <p className="text-2xl font-bold">{credits}</p>
                </div>
                <div>
                  <p className="text-sm text-text-secondary">Cash Balance</p>
                  <p className="text-2xl font-bold">${cashBalance}</p>
                </div>
              </div>
              <button
                onClick={handleCashOut}
                className="w-full px-4 py-2 bg-accent-start hover:bg-accent-end text-white rounded transition-colors duration-200"
              >
                Cash Out
              </button>
            </div>
          )}
        </div>

        {isOwnProfile && (
          <div className="bg-bg-secondary rounded-lg p-4 sm:p-6 mb-6">
            <h2 className="text-xl font-bold mb-4">Revenue Overview</h2>
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-4">
                <span className="text-sm text-text-secondary">Filter by:</span>
                <select
                  value={dateFilter}
                  onChange={handleDateFilterChange}
                  className="bg-bg-primary text-text-primary rounded px-2 py-1"
                >
                  <option value="all">All time</option>
                  <option value="year">This year</option>
                  <option value="month">This month</option>
                  <option value="week">This week</option>
                </select>
              </div>
              <div className="text-right">
                <p className="text-sm text-text-secondary">Total Revenue</p>
                <p className="text-xl font-bold">${totalRevenue.toFixed(2)}</p>
              </div>
            </div>
            <div className="bg-bg-primary rounded-lg p-4">
              <RevenueChart data={revenueData} />
            </div>
          </div>
        )}

        <div className="bg-bg-secondary rounded-lg p-4 sm:p-6 overflow-hidden">
          <h2 className="text-xl sm:text-2xl font-bold mb-4">Sounds</h2>
          {isLoadingSounds ? (
            <div className="flex justify-center items-center h-32">
              <Spinner />
            </div>
          ) : (
            <div className="space-y-4">
              {userSounds.length > 0 ? (
                <SoundList
                  sounds={userSounds}
                  onLike={() => {}}
                  onUnlike={() => {}}
                  onPurchase={() => {}}
                  onPlay={() => {}}
                  onDownload={() => {}}
                />
              ) : (
                <p className="text-text-secondary">No sounds uploaded yet.</p>
              )}
              {hasMore && (
                <div ref={loadingRef} className="flex justify-center py-4">
                  <Spinner />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;