import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const HomeIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
  </svg>
);

const ProfileIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
  </svg>
);


const LikesIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
  </svg>
);

const ExploreIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 10.9c-.61 0-1.1.49-1.1 1.1s.49 1.1 1.1 1.1c.61 0 1.1-.49 1.1-1.1s-.49-1.1-1.1-1.1zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm2.19 12.19L6 18l3.81-8.19L18 6l-3.81 8.19z"/>
  </svg>
);

const UploadIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>
  </svg>
);

const MySoundsIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
  </svg>
);

const SettingsIcon = () => (
  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
    <path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z"/>
  </svg>
);

export const Navigation = ({ isOpen, onClose }) => {
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 640 && isOpen) {
        onClose();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen, onClose]);

  const navClasses = `
    fixed top-0 left-0 h-full w-64 bg-bg-secondary p-8 transition-all duration-300 ease-in-out transform z-50
    ${isOpen ? 'translate-x-0' : '-translate-x-full'}
    sm:relative sm:translate-x-0 sm:z-0
    flex flex-col justify-between
  `;

  const linkClasses = "flex items-center text-text-secondary hover:text-text-primary transition-colors duration-200";
  const subLinkClasses = "flex items-center text-text-secondary hover:text-text-primary transition-colors duration-200 pl-4";

  return (
    <>
      <nav className={navClasses}>
        <ul className="space-y-6">
          <li>
            <Link to="/" className={linkClasses} onClick={onClose}>
              <ExploreIcon /><span className="ml-2">Explore</span>
            </Link>
          </li>
          <li>
          <div className="font-semibold text-text-secondary mb-3">Library</div>
            <ul className="space-y-2">
              <li>
                <Link to="/my-sounds" className={subLinkClasses} onClick={onClose}>
                  <MySoundsIcon /><span className="ml-2">Sounds</span>
                </Link>
              </li>
              <li>
                <Link to="/likes" className={subLinkClasses} onClick={onClose}>
                  <LikesIcon /><span className="ml-2">Likes</span>
                </Link>
              </li>
            </ul>
            <br></br>

            <div className="font-semibold text-text-secondary mb-3">My Account</div>
            <ul className="space-y-3">
              <li>
                <Link to="/profile/me" className={subLinkClasses} onClick={onClose}>
                  <ProfileIcon /><span className="ml-2">Profile</span>
                </Link>
              </li>
              <li>
                <Link to="/upload" className={subLinkClasses} onClick={onClose}>
                  <UploadIcon /><span className="ml-2">Upload</span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
        <div className="mt-aut mb-4">
          <Link to="/settings" className={linkClasses} onClick={onClose}>
            <SettingsIcon /><span className="ml-2">Settings</span>
          </Link>
        </div>
      </nav>
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 sm:hidden z-40 transition-opacity duration-300 ease-in-out" 
          onClick={onClose}
        ></div>
      )}
    </>
  );
};